import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

const ColElement = styled.div`

    width: 25%;
    display: table-cell;
    text-align: center;
`;

const ColTitle = styled.div`

    font-size: 1rem;
    font-weight: 100;
`;
const ColNumber = styled.div`

    font-size: 4rem;
    font-weight: 100;
`;

const ColUnit = styled.span`

    font-size: 0.8rem;
    font-weight: 100;
`;

const Col = (props) => {
    return (
        <ColElement>
            <ColNumber>
                {props.data.main}
                { props.data.unit && <ColUnit>{props.data.unit}</ColUnit> }
            </ColNumber>
            <ColTitle>
                {props.data.title}
            </ColTitle>
        </ColElement>
    );
};

Col.propTypes = {
    data: PropTypes.object.isRequired
};
Col.defaultProps = {};

export default Col;
