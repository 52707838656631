import React, {Component} from 'react';
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

const About = styled(Link)`

    position: absolute;
    top: 5%;
    right: 5%;
    display: block;
    z-index: 11;

    transition: all 0.5s;

    ${ (props) => {

        if( props.animationState === 'run' ){

            return css`

                transform: translateY(0);
                opacity: 1;
`;
        }
        else{

            return css`

                transform: translateY(1rem);
                opacity: 0;
`;
        }
}  }
`;
class AboutLink extends Component{

    constructor(props) {
        super(props);

        this.state = {

            animationState: 'start'
        };
    }

    componentDidMount(){

        setTimeout( () => {

            this.setState({animationState: 'run'});
        }, 5000 );
    }

    render(){

        return (

            <About to="/about" animationState={this.state.animationState}>about</About>
        );
    }
}

export default AboutLink;
