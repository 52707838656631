import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'
import SectionTitle from './SectionTitle'

const SectionElement = styled.div`

    ${ (props) => {


        if( props.state == 'titleLeft' ){

            return css`

            display: table;

            &>div{

                display: table-cell;
                text-align: left;
            }

            &>div:first-child{

                width: 25%;
                vertical-align: middle;
                text-align: center;
            }

            &>div:last-child{

                width: 75%;
            }
`;
        }
        else if( props.state == 'titleTop' ){

            return css`

            &>div:first-child{

                margin-bottom: 2rem;
                padding: 1rem 0;
                position: relative;

                &:after{

                    display: block;
                    width: 20px;
                    height: 2px;
                    background: #000;
                    display: block;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    content: '';
                }
            }

            &>div:last-child{


            }
`;
        }
} }
`;

const SectionTitlePlace = styled.div`

`;

const SectionContentPlace = styled.div`

`;

const Section = (props) => {

    let title = props.title ? <SectionTitlePlace ><SectionTitle>{props.title}</SectionTitle></SectionTitlePlace> : null;
    let content = <SectionContentPlace >{props.content}{props.children}</SectionContentPlace>;

    return (
        <SectionElement state={props.state}>

            {title}
            {content}
        </SectionElement>
    );
};

Section.propTypes = {

    state: PropTypes.string,
    content: PropTypes.string
};
Section.defaultProps = {
    state: 'titleTop'
};

export default Section;
