import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

import Button from '../Button/Button'
import VideoPlayer from './VideoPlayer'



const ButtonPlace = styled.div`

    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 1995;
`;

const VideoContainer = styled.div`

    position: relative;
    min-width: 600px;
    max-width: 600px;
    width: 100%;
    padding-top: 75%;
`;

const VideoPlace = styled.div`

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;

    & > video {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

class Video extends Component {

    constructor(props) {
        super(props);

        this.state = {

            playing: false
        }
    }

    render(){


        return (
            <VideoContainer>

                <VideoPlace>
                    <VideoPlayer url={this.props.url} playing={this.state.playing} />
                </VideoPlace>

                <ButtonPlace onClick={ (e) => this.setState({ playing: !this.state.playing })  }>
                    <Button>{ this.state.playing ? 'Pause' : 'Play' }</Button>
                </ButtonPlace>

            </VideoContainer>
        );
    }
}

Video.propTypes = {

    url: PropTypes.string.isRequired
};
Video.defaultProps = {

    url: ''
};

export default Video;
