import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

const Title = styled.div`

    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
`;

const SectionTitle = (props) => {
    return (
        <Title>

            {props.children}
        </Title>
    );
};

SectionTitle.propTypes = {};
SectionTitle.defaultProps = {};

export default SectionTitle;
