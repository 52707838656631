import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

const Container = styled.div`

    a{
        display: inline-block;
        margin-right: 1rem;

        img{

            width: auto;
            height: 1.25rem;
        }

        &:last-child{

            margin-right: 0;
        }

        &:hover{

            opacity: 0.5;
        }
    }
`;

const Social = (props) => {

    return (
        <Container>
            <a href="https://www.linkedin.com/in/mojtabast/" target="_blank"><img src="./files/icons/linkedin.svg" alt=""/></a>
            <a href="http://www.facebook.com/esparipour" target="_blank"><img src="./files/icons/facebook.svg" alt=""/></a>
            <a href="http://www.twitter.com/mojtabast" target="_blank"><img src="./files/icons/twitter.svg" alt=""/></a>
            <a href="http://www.instagram.com/mojtabastt" target="_blank"><img src="./files/icons/instagram.svg" alt=""/></a>
        </Container>
    );
};

Social.propTypes = {};
Social.defaultProps = {};

export default Social;
