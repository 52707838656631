import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

import SelectedWorks from '../components/Header/SelectedWorks'
import Logo from '../components/Logo/Logo'
import Slider from '../components/Slider/Slider'
import Frame from '../components/Frame'
import Actions from '../components/Action/Actions'
import SlidesList from '../components/Slider/SlidesList'
import AboutLink from '../components/AboutLink'

const Container = styled.div`

    position: relative;
    padding: 2.5% ${ props => props.theme.containerPadding };
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.5s;

    ${ props => props.color ? 'background-color: '+props.color : 'background-color: #fff' }
`;


class Index extends Component {

    constructor(props){
        super(props);

        let projects = window.Mojtabast.projects;
        this.state = {

            on: 0,
            direction: 'left',
            all: projects.length,
            projects: projects
        };


        this.changeSlide = this.changeSlide.bind(this);
    }

    changeSlide(whichOne){
        let onID;

        if( whichOne == 'next' ){

            onID = this.state.on+1 >= this.state.all ? this.state.on : this.state.on+1;
        }
        else if( whichOne == 'prev' ){

            onID = this.state.on-1 < 0 ? 0 : this.state.on-1;
        }
        else{

            onID = whichOne;
        }

        this.setState({
            on: onID,
            direction: this.state.on < whichOne ? 'left' : 'right'
        });
    }

    render() {

        let selectedProject = this.state.projects[this.state.on];
        let nextProject = this.state.projects[this.state.on+1] ? this.state.projects[this.state.on+1] : {};
        let nextTitle = nextProject.name ? nextProject.name : '';
        let prevProject = this.state.projects[this.state.on-1] ? this.state.projects[this.state.on-1] : {};
        let prevTitle = prevProject.name ? prevProject.name : '';



        return (
            <Container color={selectedProject.colors.light}>
                <AboutLink />
                <SelectedWorks year={selectedProject.year} />
                <Logo />
                <Slider cover={selectedProject.cover} direction={this.state.direction} boxColor={selectedProject.colors.main} />
                <SlidesList projects={this.state.projects} on={ this.state.on } changeSlide={ this.changeSlide } colors={selectedProject.colors} />
                <Frame url={selectedProject.url} />
                <Actions changeSlide={ this.changeSlide } nextTitle={nextTitle} prevTitle={prevTitle} />

            </Container>
        );
    }
}

Index.propTypes = {};
Index.defaultProps = {};

export default Index;
