import React from 'react';
import PropTypes from 'prop-types';

import Role from '../components/Role/Role'
import Section from '../components/Section'
import Row from '../components/Row/Row'
import Video from '../components/Video/Video'
import CenterPosition from '../components/Position/Center'
import Screenshot from '../components/Screenshot/Screenshot'
import Next from '../components/Next'
import Space from '../components/Space'


const Mehrtan = (props) => {

    let rowData = [
        { title: 'Increase PageSpeed', main: '100', unit: '%' },
        { title: 'Reduce Page Size', main: '10', unit: 'x' },
        { title: 'Increase Calling', main: '55', unit: '%' },
        { title: 'Increase Traffic', main: '75', unit: '%' }
    ];

    return (
        <div>

            <Role list={ ['design', 'structure', 'frontend', 'backend'] } />

            <Space line={true} />

            <Section
                title='Story'
                content="Mehrtan wanted to restructure and redesign their site, after carful analysis of the old website and considering all their need, the new structure was then converted to 5 parts, including web, video, radio, blog and e-commerce, Laravel was used for the back end and HTML, CSS, Javascript for the fronted."
                state="titleLeft"
            />


            <Space line={true} />

            <CenterPosition>
                <Video url="./files/cases/mehrtan/mehrtan.mp4" />
            </CenterPosition>

            <Space line={true} />

            <Screenshot type="titleRight" image="./files/cases/mehrtan/main.gif" title="Homepage" />

            <Space />

            <Screenshot type="titleLeft" image="./files/cases/mehrtan/club.gif" title="Fitness" />

            <Space />

            <Screenshot type="titleTop" image="./files/cases/mehrtan/radio.gif" title="Radio" />

            <Space />

            <Screenshot type="titleRight" image="./files/cases/mehrtan/video.png" title="Videos" />

            <Space />

            <Section
                title='What Happened After Launch?'
                content="When we launched new website we got positive feedback from users and Mehrtan's team and all the stats was improved. after 3 month we had more viewers, Google loved us more and more calling. All of the users was happy!"
                state="titleTop"
            />
            <br />

            <Row data={ rowData } />

            <Space />

            <Next name="Mahnaz Afshar" desc="A famous actress in IRAN" url="/afshar" />

        </div>
    );
};

Mehrtan.propTypes = {};
Mehrtan.defaultProps = {};

export default Mehrtan;
