import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';
import styled, {css,injectGlobal} from 'styled-components'
import { withRouter } from "react-router";

import Grana from '../containers/Grana'
import Mehrtan from '../containers/Mehrtan'
import Afshar from '../containers/Afshar'
import Logo from '../components/Logo/Logo'
import Slider from '../components/Slider/Slider'
import Button from '../components/Button/Button'
import SelectedWorks from '../components/Header/SelectedWorks'
import Space from '../components/Space'
import Actions from '../components/Action/Actions'

import ListTitle from '../components/Slider/Item/Title'
import ListDesc from '../components/Slider/Item/Desc'
import ListDevices from '../components/Slider/Item/Devices'


const Container = styled.div`

    position: relative;
    padding: 2.5% ${ props => props.theme.containerPadding };
    width: 100%;
    height: 100%;
    transition: all 0.5s;

    ${ props => props.color ? 'background-color: '+props.color : 'background-color: #fff' }

`;


const ProjectContainer = styled.div`

   position: absolute
   top: 50%;
   left: calc( 5% + 160px );
   padding-top: 13.875%;
   width: calc(90% - 320px);
   text-align: center;
   transform: translateY(1rem);
   opacity: 0;
   transition: all 1s;

    ${ props => {

        if( props.showContainer ){

            return css`

                    transform: translateY(0);
                    opacity: 1;
                `;
        }
    } }
`;

const ProjectDetail = styled.div`

   transform: translateY(-1.59375rem);
`;


class Project extends Component {

    constructor(props, context){
        super(props);


        let project = this.findProject(context);

        this.state = {

            project: project,
            showContainer: false
        };

        this.findProject = this.findProject.bind(this);
        this.changeBackgroundColor = this.changeBackgroundColor.bind(this);
    }

    findProject(){

        const {location} = this.props;

        let index = 0;
        switch( location.pathname ){

            case '/grana':
                index = 0;
                break;
            case '/mehrtan':
                index = 1;
                break;
            case '/afshar':
                index = 2;
                break;
        }

        return window.Mojtabast.projects[index];
    }

    changeBackgroundColor(){

        document.body.style.background = this.state.project.colors.light;
    };

    componentDidMount(){


        this.changeBackgroundColor();

        setTimeout( () => {

            this.setState({

                showContainer: true
            });
        }, 2000 );
    }

    componentWillReceiveProps(props){


        this.setState({

            project: this.findProject()
        }, () => {

            this.changeBackgroundColor();
        });


    }

    render() {

        let ContainerRender;
        const {location} = this.props;
        switch( location.pathname ){

            case '/grana':
                ContainerRender = <Grana />;
                break;
            case '/mehrtan':

                ContainerRender = <Mehrtan />;
                break;
            case '/afshar':

                ContainerRender = <Afshar />;
                break;
        }

        return (
            <Container color={this.state.project.colors.light}>

                <SelectedWorks year={this.state.project.year} />

                <Logo />

                <Actions type='project' />

                <Slider cover={this.state.project.cover} boxColor={this.state.project.colors.main} direction='left' coverTimeout={0} />

                <ProjectContainer showContainer={this.state.showContainer} >

                    <ProjectDetail>
                        <ListTitle color={this.state.project.colors.main}>{this.state.project.name}</ListTitle>
                        <ListDesc>{this.state.project.desc}</ListDesc>
                        <ListDevices data={ this.state.project.devices } />
                    </ProjectDetail>

                    <Space />

                    { this.state.project.launch_url && <Button href={this.state.project.launch_url}>Launch Website</Button>}

                    { this.state.project.launch_url && <Space />}

                    {ContainerRender}
                </ProjectContainer>


            </Container>
        );
    }
}

Project.propTypes = {};
Project.defaultProps = {};

export default withRouter(Project);
