import React from 'react';
import PropTypes from 'prop-types';

import SectionTitle from '../SectionTitle'
import Tags from './Tags'

const Role = (props) => {

    return (
        <div>
            <SectionTitle>Role</SectionTitle>
            <Tags list={props.list} />
        </div>
    );
};

Role.propTypes = {};
Role.defaultProps = {};

export default Role;
