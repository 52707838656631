import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

import Logo from '../components/Logo/Logo'
import Actions from '../components/Action/Actions'
import Section from '../components/Section'
import HR from '../components/HR'
import Row from '../components/Row/Row'
import Space from '../components/Space'
import Contact from '../containers/Contact'
import AboutHead from '../components/AboutHead'


const Container = styled.div`

    position: relative;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    color: #fff;
    ${ props => props.color ? 'background-color: '+props.color : 'background-color: #fff' }
`;

const AboutContainer = styled.div`

    position: relative;
    padding: 2.5% calc( ${ props => props.theme.containerPadding } + 160px );
    width: 100%;
    transition: all 0.5s;
    color: #fff;
`;


class About extends Component {

    componentWillMount(){

        document.body.style.backgroundColor = '#000';
    }
    render() {


        let experienceData = [
            { title: <span>Work<br/>Experience</span>, main: '9', unit: 'yrs' },
            { title: <span>Companies<br/>I worked for</span>, main: '+12' },
            { title: <span>Using Language<br/>Or Technology</span>, main: '+12' },
            { title: <span>Finished<br/>Projects</span>, main: '+15' },
        ];

        let companiesData = [
            { title: <img src="./files/logos/snappmarket.svg" style={{width: "11rem"}} /> },
            { title: <img src="./files/logos/parisgallery.png" /> },
            { title: <img src="./files/logos/grana.png" /> },
            { title: <img src="./files/logos/roll-on.png" /> },
        ];



        return (
            <Container color="#000">

                <Logo color="#fff" />
                <Actions type="project" color="#fff"/>

                <AboutHead />

                <AboutContainer>

                    <Space height={0.25} />

                    <Section content="Award-winning designer and developer with 9 years experience in the industry. Pushing the limits to create high standard designs. Able to use different tools in every phase of design and development depending on needs of each project and able to quickly learn specialized tools needed to have superior end results. Able to work on a digital product from scratch with consideration of all the aspects. hungry to learn new thing and achieve better than expected results. I worked for many companies across the world including Germany, USA, Canada, Malaysia and Iran." />

                    <Space  />

                    <Section title="What I Do" content="Able to make your ideas into reality, or achieve a great online presence with the highest quality and standards available. Being able to handle the project no matter what parts are needed and using the latest tools, your ideas will come to life as you have imagined them. With years of experience fronted or back end doesn’t make a difrence, it will be done to the highest quality available." />

                    <Space />

                    <Section title="Experience" />

                    <Space height={0.5} />

                    <Row data={experienceData} />

                    <Space />
                    <HR color="rgba(255, 255, 255, 0.1)" />
                    <Space />

                    <Row data={companiesData} />

                    <Space />

                    <Contact />

                    <Space />
                </AboutContainer>

            </Container>
        );
    }
}

About.propTypes = {};
About.defaultProps = {};

export default About;
