import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

const SpaceElement = styled.div`

    padding-top: ${ (props) => { return props.height*10 } }rem ;
    position: relative;

    &:after{

        position: absolute;
        left: 50%;
        top: 50%;
        background: #ccc;
        width: 20px;
        height: 2px;
        display: none;
        transform: translateX(-50%);
        content: '';
    }

    ${ (props) => {

        if( props.line ){

            return css`

            &:after{

                display: block;
            }
`;
        }
} }
`;

const Space = (props) => {
    return (
        <SpaceElement height={props.height} line={props.line} />
    );
};

Space.propTypes = {
    height: PropTypes.number.isRequired,
    line: PropTypes.bool
};
Space.defaultProps = {

    height: 1,
    line: false
};

export default Space;
