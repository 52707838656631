import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Cover from './Cover'

const SlideContainer = styled.div`
    position:absolute;
    width: 37%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // background: #ddd;

    &:after{

        width: 100%;
        padding-top: 75%;
        display: block;
        content: '';
    }
`;

const Slide = (props) => {
    return (
        <SlideContainer>

            <Cover cover={props.cover} direction={props.direction} boxColor={props.boxColor} coverTimeout={props.coverTimeout} />

        </SlideContainer>
    );
};

Slide.propTypes = {
    cover: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    boxColor: PropTypes.string.isRequired,
    coverTimeout: PropTypes.number
};
Slide.defaultProps = {};

export default Slide;
