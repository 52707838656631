import React from 'react';
import PropTypes from 'prop-types';

import Role from '../components/Role/Role'
import Section from '../components/Section'
import Row from '../components/Row/Row'
import Video from '../components/Video/Video'
import CenterPosition from '../components/Position/Center'
import Screenshot from '../components/Screenshot/Screenshot'
import Next from '../components/Next'
import Space from '../components/Space'


const Afshar = (props) => {

    let rowData = [
        { title: 'Increase PageSpeed', main: '100', unit: '%' },
        { title: 'Reduce Page Size', main: '10', unit: 'x' },
        { title: 'Increase Calling', main: '55', unit: '%' },
        { title: 'Increase Traffic', main: '75', unit: '%' }
    ];

    return (
        <div>

            <Role list={ ['design', 'structure', 'frontend'] } />

            <Space line={true} />

            <Section
                title='Story'
                content="Mahnaz Afshar is a famous and popular actress in Iran. We decided to make the project bilingual, and I provided the template of all the work, which is now approved. She is awaiting funding and sponsorship to move forward."
                state="titleLeft"
            />


            <Space line={true} />

            <CenterPosition>
                <Video url="./files/cases/afshar/mahnaz.mp4" />
            </CenterPosition>

            <Space line={true} />

            <Screenshot type="titleRight" image="./files/cases/afshar/home.gif" title="Homepage" />

            <Space />

            <Screenshot type="titleLeft" image="./files/cases/afshar/gallery.png" title="Pictures" />

            <Space />

            <Screenshot type="titleTop" image="./files/cases/afshar/news.gif" title="News" />

            <Space />

            <Next name="Grana" desc="A mid-to-luxury sanitary faucets brand in IRAN" url="/grana" />

        </div>
    );
};

Afshar.propTypes = {};
Afshar.defaultProps = {};

export default Afshar;
