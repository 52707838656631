import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

const Container = styled.div`

    width: 100%;
    display: table;

     ${ (props) => {

         if( props.type == 'titleLeft' ){

             return css`
     &>div{

        display: table-cell;
        width: 50%;
        vertical-align: middle;
    }
    &>div:first-child{


    }
    &>div:last-child{

    }
`;
         }
         else if( props.type == 'titleRight' ){

             return css`
    &>div{

        width: 50%;
    }

    &>div:first-child{

        float: right;
    }

    &>div:last-child{

        float: left;
    }
`;
         }
         else if ( props.type == 'titleTop' ) {

             return css`

             &>div:first-child{

                margin-bottom: 1rem;
            }
`;
         }
}
}

`;


const ImagePlace = styled.div`

    & > img{

        width: 100%;
    }
`;

const TitlePlace = styled.div`

    font-size: 6vw;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.1);
    font-weight: bold;
`;

const Screenshot = (props) => {
    return (
        <Container type={props.type}>

            <TitlePlace>{props.title}</TitlePlace>
            <ImagePlace><img src={props.image} /></ImagePlace>

        </Container>
    );
};

Screenshot.propTypes = {

    type: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
Screenshot.defaultProps = {
    type: 'titleRight'
};

export default Screenshot;
