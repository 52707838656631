import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Slide from './Slide'

const SliderContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

`;
const Slider = (props) => {
    return (
        <SliderContainer>
            <Slide cover={props.cover} direction={ props.direction } boxColor={props.boxColor} coverTimeout={props.coverTimeout} />
        </SliderContainer>
    );
};

Slider.propTypes = {
    cover: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    boxColor: PropTypes.string.isRequired,
    coverTimeout: PropTypes.number
};
Slider.defaultProps = {};

export default Slider;
