import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'
import ListItem from './ListItem'

const Container = styled.div`
    position: absolute;
    bottom: 2.5%;
    left: 0;
    width: 100%;
    height: 137px;

    .item{

        &>div{

            opacity: 0;
            transform: translateY(1rem);
            transition: all 0.5s;
            white-space: nowrap;

        }
    }

    ${ props => {

        if( props.state == 'running' ){

            return css`

            .item{

                &>div{

                    opacity: 1;
                    transform: translateY(0);


                    &:nth-child(1){

                        transition-delay: 0.1s;
                    }
                    &:nth-child(2){

                        transition-delay: 0.2s;
                    }
                    &:nth-child(3){

                        transition-delay: 0.3s;
                    }
                    &:nth-child(4){

                        transition-delay: 0.4s;
                    }
                    &:nth-child(5){

                        transition-delay: 0.5s;
                    }
                }
            }
`;
        }
        else if( props.state == 'finished' ){


            return css`

            .item{

                &>div{

                    opacity: 1;
                    transform: translateY(0);
                    transition-delay: 0;
                    transition: none;
                }
            }
`;
        }
} }
`;

class SlidesList extends Component {

    constructor(props){
        super(props);

        this.state = {

            animationState: 'start'
        };
    }

    componentDidMount(){

        setTimeout( () => {

            this.setState({

                animationState: 'running'
            } );


            setTimeout( () => {

                this.setState({

                    animationState: 'finished'
                } );
            }, 1000 );

        }, 3000 );
    }

    render(){


        let data = this.props.projects;
        let on = this.props.on;

        return (
            <Container state={this.state.animationState}>


                {
                    data.map( (item, i) => {

                        let whichType;
                        if( i == on ){

                            whichType = 'selected';
                        }
                        else if( i+1 == on ){

                            whichType = 'prev';
                        }
                        else if( i-1 == on ){

                            whichType = 'next';
                        }
                        else if( i < on ){

                            whichType = 'prev2';
                        }
                        else if( i > on ){

                            whichType = 'next2';
                        }
                        else{

                            whichType = 'none';
                        }


                        return <ListItem key={i} colors={this.props.colors} whichType={whichType} onClick={  () => this.props.changeSlide(i) } data={item} index={i} />;
                    } )
                }


            </Container>
        );
    }
};

SlidesList.propTypes = {

    colors: PropTypes.object.isRequired,

};
SlidesList.defaultProps = {};

export default SlidesList;
