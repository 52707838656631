import React  from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'


const TitleStyle = styled.div`

    display: inline-block;
    font-size: 2rem;
    text-transform: uppercase;
    transition: all 0.5s;

    ${ props => props.color ? 'color: '+props.color : '' }
`;

const Title = (props) => {
    return (
        <TitleStyle color={props.color} className="title">
            {props.children}
        </TitleStyle>
    );
};

Title.propTypes = {

};
Title.defaultProps = {};

export default Title;
