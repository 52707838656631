import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'
import Col from './Col'

const RowElement = styled.div`

    display: table;
    width: 100%;
`;
const Row = (props) => {

    return (
        <RowElement>

            {
                props.data.map( (col, i) => {

                    return <Col data={col} key={i} />;
                } )
            }
        </RowElement>
    );
};

Row.propTypes = {

    data: PropTypes.array.isRequired
};
Row.defaultProps = {};

export default Row;
