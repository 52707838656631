import React, {
    Component
} from 'react';

import styled, {css} from 'styled-components';

const Btn = styled.a`

    padding: 0.5rem 2rem;
    border: 2px solid #000;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
`;

class Button extends Component {
    render() {
        return (

            <Btn {...this.props} target="_blank">{this.props.children}</Btn>
        );
    }
}

export default Button;
