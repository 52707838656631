import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

const TagsElement = styled.div`

    color; #444;
    display: inline-block;
    margin-right: 0.25rem;
`;

const TagsContainer = styled.div`

    margin-top: 1rem;
`;

const Tags = (props) => {
    return (
        <TagsContainer>

            { props.list.map( (tag, i) => {

                return <TagsElement key={i}>#{tag}</TagsElement>;
            } ) }
        </TagsContainer>
    );
};

Tags.propTypes = {};
Tags.defaultProps = {};

export default Tags;
