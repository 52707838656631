import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'


const notInitStyle = css`

    &>img{

        opacity: 0;
    }

    &:after{

        left: 0%;
        transform: translateX(-100%)
    }

`;
const firstStartStyle = css`

    &>img{

        opacity: 0;
    }
    &:after{

        left: 0;
        transform: translateX(0)
    }
`;
const startStyle = css`

    &>img{

        // opacity: 0;
    }
    &:after{

        left: 0;
        transform: translateX(0);
        visibility: visible;
    }
`;

const runningStyle = css`

    &>img{

        opacity: 1;
    }
    &:after{

     transform: translateX(100%);
    }
`;


const startRightStyle = css`

    &>img{

        // opacity: 0;
    }
    &:after{

        left: 0%;
        transform: translateX(0%);
        visibility: visible;
    }
`;

const runningRightStyle = css`

    &>img{

        opacity: 1;
    }
    &:after{

     transform: translateX(-100%);
    }
`;
const finishedStyle = css`

    &>img{

        opacity: 1;
    }
    &:after{

        transition: none;
        transform: translateX(-100%);
        visibility: hidden;
    }
`;

const finishedRightStyle = css`

    &>img{

        opacity: 1;
    }
    &:after{

        transition: none;
        transform: translateX(100%);
    }
`;

const projectStartStyle = css`

    &>img{

        opacity: 0;
        transform: translateY(1rem);
        transition: all 0.5s;
    }
`;

const projectEndStyle = css`

    &>img{

        opacity: 1;
        transform: translateY(0);
                transition: all 0.5s;

    }
`;

const CoverStyle = styled.div`

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & > img{

        // width: 100%;
        width: 100%;
        height: 100%;
        // opacity: 0;


    }


    &:after{

        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        ${ props => props.boxColor ? 'background-color: '+props.boxColor : 'background-color: #eee;' }
        top: 0;
        left: 0;
        transition: transform 0.5s;
        transform: translateX(-100%);
        content: '';
    }


        ${ (props) => {

            if( props.state == 'notInit' ) {

                return notInitStyle;
            }
            else if( props.state == 'firstStart' ) {

                return firstStartStyle;
            }
            else if( props.state == 'start' ) {

                return props.direction == 'right' ?  startRightStyle : startStyle;
            }
            else if( props.state == 'running' ) {

                return props.direction == 'right' ? runningRightStyle : runningStyle;
            }
            else  if( props.state == 'finished' ){

                return props.direction == 'right' ? finishedRightStyle : finishedStyle;
            }
            else  if( props.state == 'projectStart' ){

                return projectStartStyle;
            }
            else  if( props.state == 'projectEnd' ){

                return projectEndStyle;
            }
        }}

`;

class Cover extends Component {

    constructor(props){

        super(props);

        this.state = {

            animationState: 'notInit',
            cover: this.props.cover
        };

        this.runAnimation = this.runAnimation.bind(this);
    }

    runAnimation(data){

        let startTime = !!data.first ? 1500 : 100;
        startTime = typeof this.props.coverTimeout == 'undefined' ? startTime : this.props.coverTimeout;


        if( startTime == 0  ) {

            this.setState({

                animationState: 'projectStart'
            });

            setTimeout( () => {

                this.setState({

                    animationState: 'projectEnd'
                });

            }, 1000);
        }
        else{
            setTimeout( ()=> {


                // step 1
                if( !!data.first == false ){

                    this.setState({

                        animationState: 'start'
                    });
                }
                else{

                    this.setState({

                        animationState: 'firstStart'
                    });
                }


                setTimeout(()=>{

                    // step 2
                    let runningState = data.cover ? {

                        animationState: 'running',
                        cover: data.cover
                    } : {

                        animationState: 'running'
                    };

                    this.setState(runningState);


                    setTimeout(()=>{

                        // step 3
                        this.setState({

                            animationState: 'finished'
                        });
                    }, 500);
                }, 1000);

            }, startTime );
        }

    }
    componentDidMount(){

        this.runAnimation({first: true});
    }

    componentWillReceiveProps(newProps){

        if( this.props.coverTimeout == 0) return false;
        this.runAnimation({ cover: newProps.cover});
    }

    render() {

        return (
            <CoverStyle state={this.state.animationState} direction={this.props.direction} boxColor={this.props.boxColor}>
                <img src={this.state.cover}/>
            </CoverStyle>
        );
    }
}

Cover.propTypes = {
    cover: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    boxColor: PropTypes.string.isRequired,
    coverTimeout: PropTypes.number
};

Cover.defaultProps = {

    direction: 'left'
};

export default Cover;
