import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

import ListTitle from './Item/Title'
import ListDesc from './Item/Desc'
import ListNumber from './Item/Number'
import ListDevices from './Item/Devices'


const selectedCSS = css`
    left: 50%;
    transform: translateX(-50%);
    color: #000;

    .devices{

        img{

            opacity: 1;
        }

    }
`;
const prevCSS = css`
    left: 0%;
    transform: translateX(-50%);
`;
const prev2CSS = css`
    left: -100%;
`;
const nextCSS = css`
    left: 100%;
    transform: translateX(-50%);
`;
const next2CSS = css`
    left: 200%;
`;

const ItemElements = styled.div`
    position: absolute;
    left: 0;
    transition: all 0.5s;
    text-align:center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.2);

    .devices{

        img{

        opacity: 0.2;
        transition: all 0.5s;
        }

    }

    ${ (props) => {

    if(props.whichType == 'selected'){

        return css`
            ${selectedCSS}

            .title{

                color: ${ props => props.colors.main ? props.colors.main : '#000' }
            }
        `;
    }
    else if ( props.whichType == 'prev' ){

        return prevCSS;
    }
    else if ( props.whichType == 'prev2' ){

        return prev2CSS;
    }
    else if ( props.whichType == 'next' ){

        return nextCSS;
    }
    else if ( props.whichType == 'next2' ){

        return next2CSS;
    }
} }
`;

const ListItem = (props) => {
    let data = props.data;

    return (
        <ItemElements {...props} className="item">
            <ListNumber number={props.index} />
            <ListTitle>{data.name}</ListTitle>
            <ListDesc>{data.desc}</ListDesc>
            <ListDevices data={ data.devices } />
        </ItemElements>
    );
};


ListItem.propTypes = {
    colors: PropTypes.object.isRequired,
};
ListItem.defaultProps = {};

export default ListItem;
