import React  from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'


const NumberStyle = styled.div`

    font-size: 0.8rem;
    color: ${ props => props.theme.gray }
`;

const Number = (props) => {

    let num = props.number > 10 ? props.number+1 : '0'+(props.number+1);

    return (
        <NumberStyle>

            {num}.
        </NumberStyle>
    );
};

Number.propTypes = {};
Number.defaultProps = {};

export default Number;
