import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import {Link} from 'react-router-dom'

const LogoContainer = styled(Link)`
    position: fixed;
    top: 50%;
    left: ${ props => props.theme.containerPadding };
    transform: translateY(-50%);
    z-index: 1995;
`;

const Logo = (props) => {
    return (
        <LogoContainer to="/">
            <svg width="75px"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  viewBox="0 0 49.927 48.852" enableBackground="new 0 0 49.927 48.852" fill={props.color}>
                <path d="M44.427,5.5v37.852H5.5V5.5H44.427 M49.927,0h-5.5H5.5H0v5.5v37.852v5.5h5.5h38.927h5.5v-5.5V5.5V0L49.927,0z"/>
                <path d="M24.89,30.827h0.111l6.94-19.909h7.068v27.016h-5.418V19.49l-0.111-0.019L26.8,37.934h-3.673l-6.68-18.5l-0.111,0.019 v18.481h-5.418V10.918h7.106L24.89,30.827z"/>
            </svg>
        </LogoContainer>
    );
};


Logo.propTypes = {
    color: PropTypes.string
};
Logo.defaultProps = {

    color: '#000'
};

export default Logo
