import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

import ListTitle from './Slider/Item/Title'
import ListDesc from './Slider/Item/Desc'
import ListSubText from './Slider/Item/SubText'


const NextElement = styled.div`

    color: ${ props => props.theme.gray}
    padding: 1rem 0;

    &:hover{

        color: #000;
        cursor: pointer;
    }


    &>div{

        transition-delay: 0 !important;
        transition: all 0.5s;
    }
`;

const Next = (props) => {
    return (<Link to={props.url} >
        <NextElement>
            <ListSubText text="NEXT" />
            <ListTitle>{props.name}</ListTitle>
            <ListDesc>{props.desc}</ListDesc>
        </NextElement>
        </Link>
    );
};

Next.propTypes = {

    name: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};
Next.defaultProps = {
    url: '/'
};

export default Next;
