import React from 'react';
import {render} from  'react-dom'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from 'react-ga'
import styled,{ThemeProvider, createGlobalStyle} from 'styled-components'

import Index from './views/Index'
import About from './views/About'
import Project from './views/Project'

import theme from './config/theme'
const GlobalStyle = createGlobalStyle`

    *, *:before, *:after{

        box-sizing: inherit;
    }

    html{
        box-sizing: border-box;
    }
    html, body{

        width: 100%;
        height: 100%;
        position: relative;
    }
    body{

        padding: 0;
        margin: 0;
        font: 1rem 'Roboto';
        line-height: 1.6;
    }

    a:hover{

        color: rgba(0, 0, 0, 0.5);
    }
`;

ReactGA.initialize('UA-91434436-1');
function logPageView() {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
}


render(<ThemeProvider theme={theme}>
      <React.Fragment>
         <GlobalStyle />
        <Router onUpdate={logPageView}>
          <Switch>
            <Route path='/grana' component={Project} />
            <Route path='/mehrtan' component={Project} />
            <Route path='/afshar' component={Project} />
            <Route path='/about' component={About} />
            <Route path='/' component={Index} />
          </Switch>
        </Router>
      </React.Fragment>
    </ThemeProvider>,
    document.querySelector('#ThisIsMojtabast'));

