import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'
import SectionTitle from '../components/SectionTitle';
import Social from '../components/Social'

const Container = styled.div`

    text-align: center;
`;

const Mail = styled.a`

    color: #fff;

    &:hover{

        color: rgba(255, 255, 255, 0.5);
    }
`;

const Contact = (props) => {
    return (
        <Container>

            <SectionTitle>Contact</SectionTitle>
            <br />
            <Social />
            <br />
            <Mail href="mailto:hi@mojtabast.com">hi@mojtabast.com</Mail>
        </Container>
    );
};

Contact.propTypes = {};
Contact.defaultProps = {};

export default Contact;
