import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

const CenterPos = styled.div`

    position: relative;
    // left: 50%;
    // transform: translateX(-50%);
    display: inline-block;
`;

const Center = (props) => {

    return <CenterPos {...props} />;
};

Center.propTypes = {};
Center.defaultProps = {};

export default Center;
