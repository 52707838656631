import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

const FrameContainer = styled.div`

    position: absolute;
    top: 50%;
    left: 50%;
    width: calc( 37% - 4rem );
    border: 0px solid #000;
    transform: translate(-50%, -50%);

    &:before{

        display: block;
        padding-top: calc( 75% - 1.3333333rem );
        content: '';
    }


`;

const View =  styled.div`

    text-transform: uppercase;
    display: inline-block;
    padding: 1rem;
    position: absolute;
    left: 50%;
    bottom: 0.5rem;
    transform: translate(-50%, 0.5rem);
    opacity: 0;
    transition: all 0.5s, opacity 0.5s 2s;

    &:hover{

        cursor: pointer;
        transform: translate(-50%, -0.25rem);
    }

    ${ (props) => {

        let style = css`
        opacity: 1;
        transform: translate(-50%, 0);
                `;
        if( props.state == 'finished' ){

            return style;
        }
    }
    }

`;

const Border = styled.div`

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after, &:before{

        width: 100%;
        height: 5px;
        background: #000;
        position: absolute;
        left: 0;
        transform: scale3d(0, 1, 1);
        content: '';
    }

    &:after{

        top: 0;
        transform-origin: 0 50%;
    }

    &:before{

        bottom: 0;
        transform-origin: 100% 50%;
    }

    &>div{

        &:after, &:before{

            width: 5px;
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            transform: scale3d(1, 0, 1);
            content: '';
        }

        &:after{

            left: 0;
            transform-origin: 50% 100%;
        }

        &:before{

            right: 0;
            transform-origin: 50% 0%;
        }
    }

    &:after, &:before, &>div:before,  &>div:after{

        transition: all 0.25s;
    }

    ${
        (props) => {

            let style = css`
                    &:after, &:before, &>div:before,  &>div:after{

                        transform: scale3d(1, 1, 1);
                    }



                    &:after{

                        transition-delay: 0s;
                    }
                    &:before{

                        transition-delay: 0.5s;
                    }
                    &>div{

                        &:after{

                            transition-delay: 0.75s;
                        }
                        &:before{

                            transition-delay: 0.25s;
                        }
                    }
                `;

            if(props.state == 'running'){

                return style;
            }
            else if( props.state == 'finished' ){

                return style;
            }
        }
    }


`;

class Frame extends Component {

    constructor(props){

        super(props);


        this.state = {

            animationState: 'start'
        }
    }

    componentDidMount(){

        setTimeout( () => {

            this.setState({

                animationState: 'running'
            });


            setTimeout( () => {

                this.setState({

                    animationState: 'finished'
                });
            }, 500 );

        }, 250 );


    }

    render(){

        return (
            <FrameContainer>
                <Border state={this.state.animationState}><div></div></Border>

                <Link to={this.props.url} > <View state={this.state.animationState}>View Case</View> </Link>
            </FrameContainer>
        );
    }
}

Frame.propTypes = {

    url: PropTypes.string.isRequired
};
Frame.defaultProps = {};

export default Frame;
