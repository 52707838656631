import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'


const DescStyle = styled.div`

`;

const Desc = (props) => {
    return (
        <DescStyle>
            {props.children}
        </DescStyle>
    );
};

Desc.propTypes = {};
Desc.defaultProps = {};

export default Desc;
