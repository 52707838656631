import React  from 'react';
import PropTypes from 'prop-types';

import Role from '../components/Role/Role'
import Section from '../components/Section'
import Row from '../components/Row/Row'
import Video from '../components/Video/Video'
import CenterPosition from '../components/Position/Center'
import Screenshot from '../components/Screenshot/Screenshot'
import Next from '../components/Next'
import Space from '../components/Space'


const Grana = (props) => {

    let rowData = [
        { title: 'Unified Platform', main: '1'},
        { title: 'Increase Customer Satisfaction', main: '90', unit: '%' },
        { title: 'Increase Visitor', main: '150', unit: '%' },
        { title: 'User\'s Location', main: '+85', unit: 'Country' }
    ];



    return (
        <div>

            <Role list={ ['design', 'concept', 'strategy' , 'platform',  'backend', 'frontend', 'web_app', 'mobile_app', 'restful_api' ] } />

            <Space line={true} />

            <Section
                title='Starting Point'
                content="Grana Inc was founded on 2012 with consumers in mind to create quality products. After a few meetings regarding the steps the company wants to take, we started working together, I worked over 2 years in all aspects of the online business. Including many advertising campaigns, internal systems, blog, mobile app, and web. I was responsible for all online platforms for Grana."
                state="titleLeft"
            />


            <Space line={true} />

            <Section
                title='Design'
                content="In the beginning I started with studying our competition and the aspects of which we are able to provide a new way to advertise and present our brand and products. after research on the direction the company is going to take and what design concepts they are interested in, together with the company we chose a simple but elegant design with use of minimal and effective animations that grabs the attentions of the users. With this simple and clean process I was able to focus on quality of the products with a beautiful and simple to use platform for the user."
                state="titleLeft"
            />

            <Space line={true} />

            <CenterPosition>
                <Video url="./files/cases/grana/video1.mp4" />
            </CenterPosition>

            <Space line={true} />

            <Screenshot type="titleRight" image="./files/cases/grana/homepage.png" title="Homepage" />

            <Space />

            <Screenshot type="titleLeft" image="./files/cases/grana/category.png" title="Category" />

            <Space />

            <Screenshot type="titleTop" image="./files/cases/grana/product.png" title="Product" />



            <Space line={true} />

            <Section
                title='Platform'
                content="At the start of the relationship, we were able to sent short and long goals, the result of this is not only a platform for the users but also internal use for the employees and distributors. Everything that Grana needs is now under one roof with the use of their website and mobile apps."
                state="titleLeft"
            />

            <Space />

            <Section
                title='What Happened After Launch?'
                content="Using this direction and research we were able to enter and win many competitions and were featured in many different galleries both domestic and international. Including the much respected awards site. The quality of the site also attract 5 new counties who are now looking to have the products exported to them. We were ale to use this exclusive platform to help users and distributors get the information and products they need in the fastest possible time and the satisfaction rate to over 90 percent."
                state="titleTop"
            />
            <br />

            <Row data={ rowData } />

            <Space />

            <Next name="Mehrtan" desc="this is a long text as much as i can" url="/mehrtan" />

        </div>
    );
};

Grana.propTypes = {};
Grana.defaultProps = {};

export default Grana;
