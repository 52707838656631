import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'


const DevicesStyle = styled.div`

    display: block;
    padding: 0.5rem 0;

    & > img {

        display: inline-block;
        margin-right: 0.5rem;

        &:last-child{

            margin-right: 0;
        }
    }
`;

const Devices = (props) => {

    return (
        <DevicesStyle className="devices">

            { props.data.computer ? <img src="./files/icons/laptop.svg" /> : ''  }
            { props.data.tablet ?  <img src="./files/icons/tablet.svg" /> : ''  }
            { props.data.mobile ?  <img src="./files/icons/mobile.svg" /> : ''  }

        </DevicesStyle>
    );
};

Devices.propTypes = {
    data: PropTypes.object.isRequired
};
Devices.defaultProps = {};

export default Devices;
