import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

const Text = styled.div`
    color: ${ props => props.theme.gray };
    text-transform: uppercase;
    position: relative;
    // display: inline-block;
    transform: translateY(0.5rem);
    opacity: 0;
    transition: all 0.5s;

    &:after{

        position: absolute;
        height: 0;
        width: 2px;
        background: ${ props => props.theme.gray };
        left: 50%;
        bottom: -1rem;
        transform: translate(-50%, 100%);
        transition: all 0.5s 0.5s;
        content: '';
    }
`;

const Year = styled.div`

    // color: ${ props => props.theme.gray };
    display: inline-block;
    padding-top: 3rem;
    transition: all 0.5s;
    transform: translateY(0.5rem);
    opacity: 0;
    transition-delay: 1s;

`;

const Container = styled.div`

    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    ${ (props) => {

        if( props.state == 'running' ){

            return css`


    // text
    &>div:first-child{

        transform: translateY(0rem);
        opacity: 1;

        &:after{

            height: 1rem;
        }
    }

    // year
    &>div:last-child{

        transform: translateY(0rem);
        opacity: 1;
    }
`;
        }
} }
`;

class SelectedWorks extends Component{

    constructor (props) {
        super(props);

        this.state = {

            animationState: 'start',
        }
    }

    componentDidMount(){

        setTimeout( () => {

            this.setState({

                animationState: 'running'
            });

            setTimeout( () => {

                this.setState({

                    // animationState: 'finished'
                } );
            }, 1000 );

        } ,3000);
    }
    render(){

        return (
            <Container state={this.state.animationState}>
                <Text>Selected Works</Text>
                <Year>{this.props.year}</Year>
            </Container>
        );
    }
}

SelectedWorks.propTypes = {

    year: PropTypes.string.isRequired
};
SelectedWorks.defaultProps = {};

export default SelectedWorks;
