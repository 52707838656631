import React from 'react';
import styled from 'styled-components'

const Container = styled.div`

    width: 100%;
    height: 402px;
    background-image: url('./files/mojtaba.jpg?2');
    background-position: center bottom;
    background-repeat: no-repeat;
    margin-top: 4rem;
    position: relative;
    background-size: 600px;
`;

const Name = styled.div`

    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    text-align: center;
    font-size: 2.6rem;
    font-weight: 100;
    text-transform: uppercase;
    line-height: 3rem;
`;
const AboutHead = (props) => {
    return (
        <Container>
            <Name>Mojtaba<br/>Espari Pour</Name>
        </Container>
    );
};

export default AboutHead;
