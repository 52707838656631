import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'

const Line = styled.div`

    background: ${ (props) => props.color };
    width: 100%;
    height: ${ (props) => props.height && props.height+'px'  };
    margin: 0.5rem 0;
`;

const HR = (props) => {

    return (
        <Line height={props.height} color={props.color} />
    );
};

HR.propTypes = {
    height: PropTypes.number,
    color: PropTypes.string,
};
HR.defaultProps = {

    height: 1,
    color: '#000'
};

export default HR;
