import React  from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'


const TextStyle = styled.div`

    font-size: 0.8rem;
    font-weight: bold;
    color: ${ props => props.theme.gray }
`;

const Number = (props) => {


    return (
        <TextStyle>

            {props.text}
        </TextStyle>
    );
};

Number.propTypes = {

    text: PropTypes.string.isRequired
};
Number.defaultProps = {};

export default Number;
