import React, {
    Component,
} from 'react';
import PropTypes from 'prop-types';

class VideoPlayer extends Component {

    constructor(props){
        super(props);

        this.state = {

            // playing: false
        };

        this.play = this.play.bind(this);

    }

    play(status){

        if( status ) this.video.play();
        else  this.video.pause();
    }

    componentWillReceiveProps(nextProps){


        this.play(nextProps.playing);
    }

    componentDidMount(){

        this.video.addEventListener("ended", () => {

            this.video.currentTime = 0;
            this.play(true);
        } );
    }

    render() {

        return (
            <video controls="" autoPlay="" ref={ (v) => this.video = v }>

                <source src={ this.props.url } type="video/mp4" />
            </video>
        );
    }
}

VideoPlayer.propTypes = {

    url: PropTypes.string.isRequired,
    playing: PropTypes.bool
};

VideoPlayer.defaultProps = {

    playing: false
};

export default VideoPlayer;
