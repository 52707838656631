import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

const ActionsContainer = styled.div`

    position: fixed;
    top: 50%;
    right: ${ props => props.theme.containerPadding }
    transform: translateY(-50%);
    z-index: 1995;
    color: ${ props => props.color };

    a{
        color: ${ props => props.color };

        &:hover{

            opacity: 0.5;
        }
    }
`;



const Button = styled.div`

    cursor: pointer;
    overflow: hidden;

    &:last-chid{

        margin-bottom: 0;
    }

    div:first-child{

        span{

            position: absolute;
            right: 0;
            transition: all 0.5s;
            opacity: 0;
        }


        span:first-child{

            opacity: 1;
            transform: scale(1);
        }
        span:last-child{

            position: absolute;
            right: 0;
            transform: translateX(-0.5rem);
            text-transform: uppercase;
        }
    }

    div:last-child{

        &>svg{

            transition: all 0.5s;
            #Shape{

                // fill: #eee;
                transition: all 0.5s;
            }
        }
    }

    &:hover{

        // Text
        div:first-child{
            // color: #000;
            opacity: 0.8;

            span:first-child{

                opacity: 0;
                transform: scale(0.8);
            }
            span:last-child{

                opacity: 1;
                transform: translateX(0);
                transition-delay: 0.25s;
            }
        }

        // Icon

        .next{

            &>svg{

                transform: translateX(0.1rem);
            }
        }

        .prev{

            &>svg{

                transform: translateX(-0.1rem);
            }
        }

        .close{

            &>svg{

                transform: scale(1.1);
            }
        }
    }


    ${ (props) => { if( props.disabled ) {

        return css`
        div:first-child{
            display: none;
        }

    div:last-child{

        &>svg{

            #Shape{

                fill: #eee;
            }
        }
    }
`;
    } } }


`;

const Text = styled.div`

    float: left;
    color: ${ props => props.theme.gray };
    position: absolute;
    right: 2.5rem;

`;


const sharedButtonStyles = css`

    float: left;
    padding: 2px 9px;
    margin-left: 0.75rem;
    z-index: 11;
    position: relative;


`;
const PrevButton = styled.div`

        ${sharedButtonStyles}
`;

const NextButton = styled.div`

    ${sharedButtonStyles}

    & > img {

        // transform: rotate(-180deg);
    }
`;

const ButtonFrame = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    border: 2px solid ${ (props) => props.color };
    height: 100%;
    width: 30px;
`;

const CloseButton = styled.div`

    position: relative;
    z-index: 11;

    &>svg{

        display: block;
        margin: 0.15rem 0.15rem 0.15rem 0;
    }
`;



const Actions = (props) => {
    let btns = [];

    if( props.type == 'home' ){

        btns.push(<Button key="1" onClick={() => ( props.changeSlide('next') ) } disabled={!props.nextTitle} >
                <Text>
                    <span>next</span>
                    <span>{props.nextTitle}</span>
                </Text>
                <NextButton className="next">
                    <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">

                        <g id="Homepage" transform="translate(-1323.000000, -517.000000)">
                            <g id="arrow" transform="translate(1286.000000, 487.000000)">
                                <g id="left" transform="translate(29.000000, 24.000000)">

                                    <g id="keyboard_x5F_arrow_x5F_down-_x28_2_x29_" transform="translate(12.500000, 13.000000) rotate(90.000000) translate(-12.500000, -13.000000) translate(5.500000, 8.500000)">
                                        <polygon id="Shape" points="12,8.3 6.8,3.2 1.6,8.3 0,6.7 6.8,0 13.5,6.7 					"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </NextButton>
            </Button>);

        btns.push(<Button key="2" onClick={() => ( props.changeSlide('prev') ) } disabled={!props.prevTitle}>
                <Text>
                    <span>prev</span>
                    <span>{props.prevTitle}</span>
                </Text>
                <PrevButton className="prev">

                    <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Homepage" transform="translate(-1323.000000, -517.000000)" fill={ props.color }>
                                <g id="arrow" transform="translate(1286.000000, 487.000000)">
                                    <g id="left" transform="translate(29.000000, 24.000000)">
                                        <g id="keyboard_arrow_down-(2)" transform="translate(12.500000, 13.000000) rotate(90.000000) translate(-12.500000, -13.000000) translate(5.500000, 8.500000)">
                                            <polygon id="Shape" points="1.58691406 0.0174409386 6.77083333 5.1450769 11.9547526 0.0174409386 13.5416667 1.58712542 6.77083333 8.28444585 0 1.58712542"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </PrevButton>
            </Button>);
    }
    else if( props.type == 'project' ){

        btns.push(<Button key="3">
            <Link to='/'>
                <Text>close</Text>
                <CloseButton className="close">
                    {/*<img src="./files/icons/close.svg" />*/}
                    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <rect id="path-1" x="0" y="0" width="25" height="25"></rect>
                            <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="25" height="25" fill="white">
                                <use xlinkHref="#path-1"></use>
                            </mask>
                            <rect id="path-3" x="0" y="0" width="25" height="25"></rect>

                        </defs>
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                            <g id="Project" transform="translate(-1315.000000, -501.000000)">
                                <g id="arrow" transform="translate(1271.000000, 501.000000)">
                                    <g id="Icons" transform="translate(44.000000, 0.000000)">
                                        <g id="right">
                                            <g id="keyboard_arrow_down-(2)" transform="translate(9.875000, 12.791667) rotate(270.000000) translate(-9.875000, -12.791667) translate(2.875000, 8.291667)" fill={ props.color }>
                                                <polygon id="Shape" points="1.58691406 0.0174409386 6.77083333 5.1450769 11.9547526 0.0174409386 13.5416667 1.58712542 6.77083333 8.28444585 0 1.58712542"></polygon>
                                            </g>
                                        </g>
                                        <g id="left">
                                            <g id="keyboard_arrow_down-(2)" transform="translate(15.500000, 13.000000) rotate(90.000000) translate(-15.500000, -13.000000) translate(8.500000, 8.500000)" fill={ props.color }>
                                                <polygon id="Shape" points="1.58691406 0.0174409386 6.77083333 5.1450769 11.9547526 0.0174409386 13.5416667 1.58712542 6.77083333 8.28444585 0 1.58712542"></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </CloseButton>
            </Link>
        </Button>);
    }

    return (
        <ActionsContainer color={props.color} >
            <ButtonFrame color={props.color} />

            {btns}

        </ActionsContainer>
    );
};

Actions.propTypes = {

    type: PropTypes.string.isRequired,
    changeSlide: PropTypes.func,
    nextTitle: PropTypes.string,
    prevTitle: PropTypes.string,
    color: PropTypes.string,
};

Actions.defaultProps = {

    type: 'home',
    color: '#000000'
};

export default Actions;
